<template>
	<div class="about">
		<v-container>
			<v-row justify="center">
				<h2 class="my-5 mx-4 py-5 px-5 text-h4 text-center">Um pouco mais sobre a gente</h2>
			</v-row>

			<v-row align="center" class="mb-5">
				<v-col>
					<v-img src="../assets/banner/about-2.jpg" max-width="360" class="float-right" />
				</v-col>

				<v-col justify="center" class="px-8">
					<h3 class="text-h5">Produto e Empresa</h3>
					<p class="text-body-1">
						<b>App Agendamento</b> é uma solução em <b>nuvem</b>, uma plataforma de agendamentos online oferecida pela <b>Mazzcorp</b>, empresa de tecnologia especializada em software de alta performance.
					</p>

					<p class="text-body-1">
						A Mazzcorp dedica um time de profissionais extremamente qualificados para manter e aprimorar a plataforma, estando sempre focados em oferecer um produto de alta qualidade com um ótimo custo benefício.
					</p>
				</v-col>
			</v-row>
		
		<v-row class="my-5">
			<v-col align="center">
				<v-img class="float-none float-sm-right my-auto" src="../assets/banner/about-1.jpg" max-width="360" />
				<div class="float-none float-sm-left text-right mt-4 px-8 about-fix-right-content-width">
					<h3 class="text-h5">Missão</h3>
					<p class="text-body-1">Acreditamos que um software deva facilitar a vida de seus usuários, nosso principal objetivo com o App Agendamento é tornar o dia a dia dos profissionais mais simples e com menos complicações.</p>

					<h3 class="text-h5">Visão</h3>
					<p class="text-body-1">A cada dia que passa o mundo está mais conectado, vemos a internet como o futuro da comunicação entre pessoas e empresas, queremos fazer parte desse futuro e queremos torná lo melhor para todos nós.</p>

					<h3 class="text-h5">Valores</h3>
					<p class="text-body-1">O princípio em que mais acreditamos é o do mutualismo, nossa forma de fazer negócios é sempre ao lado de nossos clientes e com transparência, buscamos relações duradouras e lutamos para mantê-las.</p>
				</div>
			</v-col>
		</v-row>
		</v-container>
	</div>
</template>

<script>
export default {
	name: 'about'
}
</script>

<style lang="scss">
@media (min-width: 600px) {
	.about-fix-right-content-width {
		width: calc(100% - 360px);
	}
}
</style>